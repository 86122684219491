import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Container,
  Heading,
  ListItem,
  Text,
  Box,
  UnorderedList,
} from '@chakra-ui/react';
import Layout from '../components/layout';
import data from '../data.json';

const EdicaoAtual = ({ location }) => (
  <Layout location={location.pathname}>
    {/* Primeira fase */}
    <Container mt={5} padding={4}>
      <Heading as="h1" mb={2} textAlign="center" padding={4}>
        {data.home.edicao}
        {' '}
        OCI - Primeira Fase
      </Heading>
      {/* <Box>
        <Text textAlign="center" fontWeight="bold">
          Está
          {' '}
          <Text as="u">
            aberto
          </Text>
          {' '}
          o período de inscrições!
        </Text>
      </Box> */}
      <Box>
        <Text textAlign="center" fontWeight="bold">
          Segue as provas e gabaritos da primeira fase da OCI 2024
        </Text>
      </Box>

      <Accordion mt={3}>
        {data.atual.modalidades.map((modalidade) => (
          <AccordionItem key={modalidade.id}>
            <input
              type="radio"
              id={modalidade.id}
              name="toggle"
            />
            <label htmlFor={modalidade.id}>{modalidade.label}</label>
            <AccordionButton>
              <Heading
                as="h3"
                flex="1"
                size="md"
                textAlign="start"
                textTransform="capitalize"
              >
                {modalidade.label}
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Text>Segue a prova da primeira fase e seu respectivo gabarito:</Text>
              <UnorderedList>
                <ListItem>
                  <Link
                    fontWeight="bold"
                    href={`/documentos/2024/fase1/prova_${modalidade.id}.pdf`}
                    isExternal
                  >
                    Prova
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    fontWeight="bold"
                    href={`/documentos/2024/fase1/gabarito_${modalidade.id}.pdf`}
                    isExternal
                  >
                    Gabarito
                  </Link>
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
            <AccordionPanel>
              <Text>Segue a prova da segunda fase e seu respectivo gabarito:</Text>
              <UnorderedList>
                <ListItem>
                  <Link
                    fontWeight="bold"
                    href={`/documentos/2024/fase2/prova_${modalidade.id}.pdf`}
                    isExternal
                  >
                    Prova
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    fontWeight="bold"
                    href={`/documentos/2024/fase2/gabarito_${modalidade.id}.pdf`}
                    isExternal
                  >
                    Gabarito
                  </Link>
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        ))}
        <AccordionItem key="aprovados">
          <label htmlFor="aprovados">
            <input
              type="radio"
              id="aprovados"
              name="toggle"
            />
            Aprovados
          </label>
          <AccordionButton>
            <Heading
              as="h3"
              flex="1"
              size="md"
              textAlign="start"
              textTransform="capitalize"
            >
              Aprovados
            </Heading>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text>Segue a lista de aprovados: </Text>
            <UnorderedList>
              <ListItem>
                <Link
                  fontWeight="bold"
                  href="/documentos/2024/fase1/CLASSIFICADOS.pdf"
                  isExternal
                >
                  Arquivo com a lista de alunos aprovados na primeira fase
                </Link>
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

    </Container>
  </Layout>
);

EdicaoAtual.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default EdicaoAtual;
